@font-face {
    font-family: 'Canela Thin';
    src: url('/assets/static/fonts/Canela-Thin.woff2') format('woff2'),
        url('/assets/static/fonts/Canela-Thin.woff') format('woff'),
        url('/assets/static/fonts/Canela-Thin.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
  font-family: 'Canel';
  src: url('/assets/static/fonts/Canela-Regular.woff2') format('woff2'),
      url('/assets/static/fonts/Canela-Regular.woff') format('woff'),
      url('/assets/static/fonts/Canela-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

body { font-variant-numeric: lining-nums; }

header.main-header[data-v-7ba5bd90] {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 4rem 0 2rem;
  position: relative;
}
header.main-header .main-logo[data-v-7ba5bd90] {
  display: block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translate(-50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.button-container[data-v-7ba5bd90] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  top: 3rem;
  right: 1.5rem;
  z-index: 10;
}
.button.is-primary {
  background-color: #FF563F;
  border-color: transparent;
  color: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 55px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-top: calc(0.5em - 1px);
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  margin: 1rem auto;
  font-size: 13.6px;
  font-family: "Maison Neue";
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: all 0.4s cubic-bezier(0.17, 0.67, 0.26, 0.98);
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.26, 0.98);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  line-height: 40px;
  &:hover {
    background-color: #ff4b32;
    border-color: transparent;
    color: #fff;
  }
}
@media screen and (min-width: 1480px) {
.button-container[data-v-7ba5bd90] {
    left: calc(50vw + 630px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
}
@media screen and (max-width: 1023px) {
.button-container[data-v-7ba5bd90] {
    top: 1.5rem;
}
}
@media screen and (max-width: 768px) {
.button-container[data-v-7ba5bd90] {
    margin: 0;
    top: inherit;
    bottom: 1rem;
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
}
}

.menu-container[data-v-ee7e6fc8] {
  position: fixed;
  top: 3.5rem;
  left: 1.5rem;
  z-index: 10;
}
.menu-container .menu-trigger[data-v-ee7e6fc8] {
  position: relative;
  font-size: 20px;
  font-family: "MaisonNeue Light";
  padding: 5px 0;
  cursor: pointer;
}
.menu-container .is-notification[data-v-ee7e6fc8] {
  right: -1.7rem;
  margin-top: -2px;
}
.is-notification[data-v-ee7e6fc8] {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  font-family: "Maison Neue";
  font-size: 11px;
  color: #fff;
  background: #FF563F;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: inherit;
  right: -0.2rem;
  margin-top: -5px;
}
.navigation[data-v-ee7e6fc8] {
  position: fixed;
  top: 0;
  left: 0;
  width: 540px;
  max-width: 100%;
  height: 100vh;
  padding: 6rem 4.4rem 3rem;
  background: #fff;
  z-index: 999;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  overflow-y: auto;
  &.has-been-opened {
    -webkit-transition: all 0.5s cubic-bezier(0.17, 0.67, 0.26, 0.98);
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.26, 0.98);
  }
}
.navigation.is-open[data-v-ee7e6fc8] {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.navigation .lang-switcher {
  text-align: left;
  margin: 0 0 30px;
  padding: 0;
}
.navigation ul[data-v-ee7e6fc8] {
  padding: 0;
  list-style: none;
}
.navigation ul li[data-v-ee7e6fc8] {
  position: relative;
  font-family: "Canela Thin";
  font-size: 2rem;
  text-align: left;
  text-transform: uppercase;
  line-height: 2.8rem;
}
.navigation ul li.agenda-link[data-v-ee7e6fc8] {
  margin-bottom: 2rem;
}
.navigation ul li .new[data-v-ee7e6fc8] {
  font-family: "Maison Neue";
  font-size: 14px;
  letter-spacing: 2px;
  display: block;
  color: #FF563F;
  line-height: 1rem;
}
.navigation ul li a[data-v-ee7e6fc8] {
  color: #000;
  text-decoration: none;
}
.navigation ul li a[data-v-ee7e6fc8]:hover {
  border-bottom: 1px solid #000;
}
.navigation .close-icon[data-v-ee7e6fc8] {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
}
.navigation .close-icon svg[data-v-ee7e6fc8] {
  -webkit-transition: all 0.4s cubic-bezier(0.17, 0.67, 0.26, 0.98);
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.26, 0.98);
}
.navigation .close-icon:hover svg[data-v-ee7e6fc8] {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  background: rgba(0, 0, 0, 0.5);
}
.fade-enter-active[data-v-ee7e6fc8], .fade-leave-active[data-v-ee7e6fc8] {
  -webkit-transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.26, 0.98);
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.26, 0.98);
  opacity: 1;
}
.fade-enter[data-v-ee7e6fc8], .fade-leave-to[data-v-ee7e6fc8] {
  opacity: 0;
}
@media screen and (min-width: 1480px) {
.menu-container[data-v-ee7e6fc8] {
    left: calc(50vw - 710px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
}
@media screen and (max-width: 1023px) {
.menu-container[data-v-ee7e6fc8] {
    top: 1.5rem;
}
.menu-container .is-notification[data-v-ee7e6fc8] {
    margin-top: -1rem;
}
}
@media screen and (max-width: 768px) {
.menu-container[data-v-ee7e6fc8] {
    top: 1rem;
    left: 1rem;
}
.navigation[data-v-ee7e6fc8] {
    padding: 5rem 2rem 3rem;
}
.navigation ul li[data-v-ee7e6fc8] {
    font-size: 1.6rem;
}
.navigation ul li .is-notification[data-v-ee7e6fc8] {
    right: -1.6rem;
}
}

.hero[data-v-dae0fb02] {
  margin-bottom: 2.5rem;
  -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.hero-body {
  -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 3rem 1.5rem;
}
.has-text-centered {
  text-align: center !important;
}
.title.is-1,
.title.is-2 {
  font-family: "Canela", "Times New Roman", Times, serif;
  text-align: center;
  text-transform: uppercase;
}
.title.is-1 {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 200;
  font-family: "Canela Thin";
  font-size: 4.5rem;
}

.hero {
  .site-header {
    padding: 1rem;
  }
  nav {
    ul {
      li {
        margin-left: 0;
        margin-right: 75px;
        padding: 0;
        font-family: "Canela Thin", "Times New Roman", Times, serif;
        display: inline-block;
        font-size: 28px;
        letter-spacing: .2px;
        a {
          &:before,
          &:after { display: none; }
          color: #000;
          text-decoration: none;
          padding: 0;
          &.on { 
            color: #FF563F; 
            &:hover { border-bottom: none; }
          }
          &:hover { border-bottom: 1px solid currentColor; }
        }
        &:last-child { margin-right: 0; }
      }
    }
  }
}

html { overflow-y: inherit !important; }
.is-clipped { overflow-y: hidden !important; }
body { overflow-y: scroll; }

.navigation {
  &.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.from-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 4.5rem 1.5rem 3rem;
  font-size: 16px;
  background-color: #fafafa;
  text-align: left;
  .container--small {
    width: 100%;
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
  }
  a { 
    color: #000; 
    text-decoration: none;
    &:hover { border-bottom: 1px solid #000; }
  }
  .columns {
    display: flex;
    margin-bottom: 3.25rem;
    margin-left: -.75rem;
    margin-right: -.75rem;
    margin-top: -.75rem;
    &:last-child { margin-bottom: 0; }
  }
  .column {
    display: block;
    margin-top: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    padding: .75rem;
    li {
      margin-bottom: 4px;
    }
  }
}

@media screen and (max-width: 800px) {
  .title.is-1 {
    font-size: 3.5rem;
    line-height: 1.1em;
  }

  .hero { margin-bottom: 1rem !important; }
  .hero-body { padding-left: 0; padding-right: 0; padding-bottom: 10px; }
  .site-header {
    padding-left: 0 !important; padding-right: 0 !important;
    nav ul {
      margin: 0; padding-left: 0; padding-right: 0;
      &:before,
      &:after { display: none !important; }
      li {
        margin-right: 30px;
        a {display: block; }
      }
    }
  }
  .from-footer {
    margin-top: -40px;
    .columns { flex-direction: column; }
  }
}